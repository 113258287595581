<template>
    <div class="flex justify-center w-full custom">
        <div class="pb-2 brand_inner_box sm:pb-4">
            <img
                :src="bottomId ? item.bottomPicture : item.resourcePicture"
                :alt="item.pictureName"
                v-for="item in imgObject"
                :key="bottomId ? item.bottomDetailId : item.resourceDetailId"
                @click="goLink(item.linkUrl)"
                style="width:100%;height:auto"
                class="observer_img"
                @load="loadImage"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgIndex: -1,
            imgObject: [], // 存储所有图片的对象
            loadIndex: 0,
            bottomId: 0,
            resourceId: 0,
        };
    },
    created() {
        // this.getBrand()
        // console.log(this.$route.query)

        if (this.$route.query.bottomId) {
            this.bottomId = this.$route.query.bottomId
            this.getBrandPage()
        }
        if (this.$route.query.pageId) {
            this.resourceId = this.$route.query.pageId
            this.getResource()
        }

    },
    methods: {
        // 页面跳转
        goLink(item) {
            if (item.ifStart) {
                window.open(item.link)
            }
        },
        // 获取底部自定义页面资源
        getBrandPage() {
            this.$request({
                methods: "get",
                url: this.$requestPath.findBottomDetail,
                params: {
                    bottomId: this.bottomId
                }
            }).then(res => {
                // console.log("获取自定义页面数据", res)
                this.imgObject = []
                if (res.code == 0) {
                    this.imgObject.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '数据获取失败' })
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        },
        // 获取自定义页面资源
        getResource() {
            this.$request({
                methods: "get",
                url: this.$requestPath.getResourceDetail,
                params: {
                    resourceId: this.resourceId
                }
            }).then(res => {
                // console.log("获取自定义页面资源", res)
                this.imgObject = []
                if (res.code == 0) {
                    this.imgObject.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取页面资源失败' })
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        },

        // 图片加载完成触发的事件
        loadImage() {
            this.loadIndex++
            if (this.loadIndex === 1) {
                this.observer()
            }
        },
        observer() {
            let list = document.querySelectorAll('.observer_img')
            let observer = new IntersectionObserver((entries) => {
                entries.forEach((element) => {
                    if (element.isIntersecting) {
                        element.target.classList.add('animation_show') // 增加show类名
                        observer.unobserve(element.target) // 移除监听
                    }
                })
            }, {
                threshold: 0.1
            })
            list.forEach((item) => observer.observe(item))
        },
    },
};
</script>

<style lang='scss' scoped>
.custom {
    min-height: var(--min-height);
}
.mask-enter-active,
.mask-leave-active {
    transition: all 2s ease;
}
.mask-enter,
.mask-leave-to {
    // transform: translateY(2.5rem);
    opacity: 0;
}

.content-enter-active,
.content-leave-active {
    transition: all 1.2s ease;
}
.content-enter,
.content-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

@media screen and (max-width: 640px) {
    .brand_inner_box {
        width: 100%;
    }
}
@media screen and (min-width: 640px) {
    .brand_inner_box {
        width: 50rem;
    }
}
</style>